import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Zoom from '@mui/material/Zoom'
import { memo } from 'react'

interface ScrollTopProps {
  visible: boolean
}

const ScrollTop = memo(({ visible }: ScrollTopProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor')
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={visible}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 90, right: 16, zIndex: 200 }}
      >
        <Fab
          sx={{
            bgcolor: 'enviLight_background.main',
            width: 48,
            height: 48,
            '&:hover': {
              bgcolor: 'rgb(237, 237,237)',
            },
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
          }}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp sx={{ color: 'enviLight_onSurface.main' }} />
        </Fab>
      </Box>
    </Zoom>
  )
})

export default ScrollTop
